import { useState, useEffect } from "react";
import TerminalIcon from "@mui/icons-material/Terminal";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import axios from "axios";
import IndvStatus from "./IndvStatus";

export default function Status() {
  const [passed, setPassed] = useState(false);
  const [password, setPassword] = useState("");
  const [passAlert, setPassAlert] = useState(false);
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const [sites, setSites] = useState([]);
  const [searchResults, setSearchResults] = useState(false);
  const [siteSearch, setSiteSearch] = useState([]);
  const [itAnimation, setItAnimation] = useState(false);

  useEffect(() => {
    axios
      .get("https://nitrous.rt-itservices.co.uk/itSupport/getAll")
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("https://nitrous.rt-itservices.co.uk/sites/data")
      .then((res) => {
        setSites(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reload]);

  const rel = () => {
    setReload(!reload);
  };

  const enter = () => {
    if (password === "NitroS2022!") {
      setPassed(true);
      setItAnimation(true)
      setTimeout(() => {
        setItAnimation(false)
      },2000)
    } else {
      setPassAlert(true);
      setTimeout(() => {
        setPassAlert(false);
      }, 2000);
    }
  };

  const result = () => {
    setSearchResults(false);
  };

  const searchSite = (site) => {
    axios
      .get(`https://nitrous.rt-itservices.co.uk/itSupport/search/?site=${site}`)
      .then((res) => {
        // console.log(res.data);
        setSiteSearch(res.data);
        setSearchResults(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  
  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      enter();
    }
  };


  return (
    <section className="status_page">
      {passAlert ? (
        <Alert
          variant="filled"
          severity="error"
          sx={{
            width: "90vw",
            margin: "auto",
          }}
        >
          Incorrect Password, please try again
        </Alert>
      ) : (
        <></>
      )}
      {passed ? (
        <div className="status">
          {itAnimation ? <section className="canvas"><div title="404">404</div></section> : <></>}

          <select
            onChange={(e) => searchSite(e.target.value)}
            style={{ marginBottom: "2%", backgroundColor: "#fff" }}
          >
            <option>Search By Site</option>
            {sites.map(function (d, i) {
              return <option>{d.name}</option>;
            })}
            <option>Head Office</option>
            <option>Crow Rd</option>
          </select>

          {searchResults ? (
            <div className="searchResults">
              <table id="entries">
                <tr>
                  <th>Site</th>
                  <th>Staff</th>
                  <th>Date</th>
                  <th>Machine Type</th>
                  <th>Issue</th>
                  <th>Status</th>
                  <th>Description</th>
                  <th>Notes</th>
                </tr>

                {siteSearch.map(function (d, i) {
                  return (
                    <IndvStatus d={d} rel={rel} result={result} notes={true} />
                  );
                })}
              </table>
            </div>
          ) : (
            <></>
          )}

          <table id="entries">
            <tbody>
              <tr>
                <th>Site</th>
                <th>Staff</th>
                <th>Date</th>
                <th>Hardware/Software</th>
                <th>Priority</th>
                <th>Status</th>
                <th>Description</th>
                <th>Notes</th>
              </tr>
              {data.map(function (d, i) {
                return (
                  <IndvStatus d={d} rel={rel} result={result} notes={true} />
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="password">
          <h2>Please put the password:</h2>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleEnter}
          />
          <Button
            variant="outlined"
            startIcon={<TerminalIcon />}
            onClick={() => enter()}
          >
            Enter
          </Button>
        </div>
      )}
    </section>
  );
}

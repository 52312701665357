import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function Description({openDescription, style, d}) {
    return (
        <Fade in={openDescription}>
            <Box sx={style}>
                <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                >
                    <u>Description</u>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                    <p>{d.issue}</p>
                </Typography>
            </Box>
        </Fade>
    );
}

import { useState, useEffect } from "react";
import Nav from "./nav/Nav";
import Login from "./Login";
import axios from "axios";
import History from "./History";
import Details from "./Details";
import { Modal } from "@mui/material";
import "../../App.scss";
import FormModal from "./modals/FormModal";

export default function Dash() {
    const [user, setUser] = useState("");
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(false);
    const [repair, setRepair] = useState({});
    const [showManual, setShowManual] = useState(false);

    useEffect(() => {
        let user = localStorage.getItem("user");
        setUser(user);
        axios
            .get("https://nitrous.rt-itservices.co.uk/itSupport/getAll")
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [reload, repair]);

    const rel = () => {
        setReload(!reload);
        setShowManual(false);
    };

    const handleRepair = (id) => {
        setRepair(id);
    };

    const handleManualOpen = async () => {
        setShowManual(true);
    };
    const handleManualClose = async () => {
        setShowManual(false);
    };

    return (
        <>
            {user ? (
                <section className="dash">
                    <Nav user={user} />
                    <section className="dash__dashboard">
                        <History
                            data={data}
                            handleRepair={handleRepair}
                            manualSubmission={handleManualOpen}
                        />
                        <Details repair={repair} rel={rel} />
                    </section>
                    <Modal
                        open={showManual}
                        onClose={handleManualClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className="modal"
                    >
                        <FormModal rel={rel} />
                    </Modal>
                </section>
            ) : (
                <Login rel={rel} />
            )}
        </>
    );
}

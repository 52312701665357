import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IosShareIcon from "@mui/icons-material/IosShare";
import IconButton from "@mui/material/IconButton";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    minHeight: "50vh",
    maxHeight: "90vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "1rem",
    overflowY: "scroll",
};
export default function CompletedJobs({
    completed,
    setStatus,
    handleCompletedStatus,
}) {
    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Completed Jobs
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div className="completed__modal">
                    <table>
                        <thead>
                            <tr>
                                <th>Site</th>
                                <th>Hardware/Software</th>
                                <th>Issue</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {completed.map(function (d, i) {
                                return (
                                    <tr>
                                        <th>{d.site}</th>
                                        <th>{d.make}</th>
                                        <th>{d.issue}</th>
                                        <th>
                                            <select
                                                onChange={(e) =>
                                                    setStatus(e.target.value)
                                                }
                                            >
                                                <option>
                                                    --Change Status--
                                                </option>
                                                <option>
                                                    Investigation Started
                                                </option>
                                                <option>Repair Arranged</option>
                                                <option>
                                                    Waiting On Parts
                                                </option>
                                                <option>
                                                    Third Party Support
                                                </option>
                                            </select>
                                            <IconButton
                                                onClick={() =>
                                                    handleCompletedStatus(
                                                        d.issueID
                                                    )
                                                }
                                            >
                                                <IosShareIcon
                                                    color="primary"
                                                    fontSize="medium"
                                                />
                                            </IconButton>
                                        </th>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </Typography>
        </Box>
    );
}

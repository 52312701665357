import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Dash from "./components/admin/Dash";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/admin" element={<Dash />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import Box from "@mui/material/Box";
import Form from "../../tabs/Form";

const manualFormStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    maxHeight: "90vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "1rem",
    overflowY: "scroll",
};
export default function FormModal({rel}) {
    return (
        <Box sx={manualFormStyle}>
            <div className="App">
                <main>
                    <Form manual={true} rel={rel} />
                </main>
            </div>
        </Box>
    );
}

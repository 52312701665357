import logo from './images/Logo.png'

export default function Nav(){

    return(
        <nav>
            <img src={logo} alt="Nitro Solutions Logo"/>
            <h1>Help Desk</h1>
        </nav>
    )
}
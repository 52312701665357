import { useState, useEffect } from "react";
import "./admin.scss";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import axios from "axios";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import ChangeStatus from "./modals/ChangeStatus";
import AddNote from "./modals/AddNote";

export default function Details(props) {
    const [repair, setRepair] = useState();
    const [reload, setReload] = useState(false);
    const [notes, setNotes] = useState([]);
    const [audit, setAudit] = useState([]);
    const [user, setUser] = useState("");
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setNote("");
    };
    const [note, setNote] = useState("");
    const [asignTo, setAsignTo] = useState("");

    const [userList, setUserList] = useState([]);

    const [statusOpen, setStatusOpen] = useState(false);
    const handleStatusOpen = () => setStatusOpen(true);
    const handleStatusClose = () => {
        setStatusOpen(false);
    };
    const [status, setStatus] = useState("");
    const [asignAlert, setAsignAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [statusAlert, setStatusAlert] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (props.repair) {
            //   console.log(props.repair);
            setRepair(props.repair);
            setNotes(props.repair.notes);
            setAudit(props.repair.statusLog);
        }

        let user = localStorage.getItem("user");
        setUser(user);

        axios
            .get("https://nitrous.rt-itservices.co.uk/itSupport/users/list")
            .then((res) => {
                setUserList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [props]);

    const rel = () => {
        props.rel();
    };

    const fetchUpdatedData = async () => {
        await axios
            .get(
                `https://nitrous.rt-itservices.co.uk/itSupport/search?site=${repair.issueID}`
            )
            .then((res) => {
                setNotes(res.data[0].notes);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const addNote = async () => {
        var noteData = { id: repair.issueID, note: note, staffName: user };
        await axios
            .post(
                "https://nitrous.rt-itservices.co.uk/itSupport/addNote",
                noteData
            )
            .then((res) => {
                setReload(!reload);
                fetchUpdatedData();
                handleClose();
                setNote("");
                //props.rel()
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const assignJob = () => {
        document.getElementById("asign_btn").style.display = "none";
        var job = {
            assigned: asignTo,
            user: user,
            id: repair.issueID,
        };
        axios
            .post("https://nitrous.rt-itservices.co.uk/itSupport/assign", job)
            .then((res) => {
                setSeverity("success");
                setMessage("Job Assigned correctly");
                setAsignAlert(true);
                setTimeout(() => {
                    setSeverity("");
                    setMessage("");
                    setAsignAlert(false);
                    setAsignTo("");
                    document.getElementById("asign_btn").style.display = "flex";
                    rel();
                }, 3000);
            })
            .catch((err) => {
                console.log(err);
                setSeverity("warning");
                setMessage("Something went wrong, please try again");
                setAsignAlert(true);
                setTimeout(() => {
                    setSeverity("");
                    setMessage("");
                    setAsignAlert(false);
                    document.getElementById("asign_btn").style.display = "flex";
                    rel();
                }, 3000);
            });
    };

    const changeStatus = async () => {
        document.getElementById("hideButton").style.display = "none";

        if (status === "") {
            setStatusAlert(true);
            setMessage("Please select a status");
            setSeverity("error");
            setTimeout(() => {
                setStatusAlert(false);
                setMessage("");
                setSeverity("");
                document.getElementById("hideButton").style.display = "block";
            }, 3500);
        } else {
            let data = {
                id: repair.issueID,
                staffName: user,
                status: status,
            };

            await axios
                .post(
                    "https://nitrous.rt-itservices.co.uk/itSupport/update",
                    data
                )
                .then((res) => {
                    setShowLoader(true);
                    setTimeout(() => {
                        setShowLoader(false);
                        setStatusOpen(false);
                        props.rel();
                    }, 3500);
                })
                .catch((err) => {
                    console.log(err);
                    setStatusAlert(true);
                    setMessage("Something went wrong, please try again");
                    setSeverity("warning");
                    setTimeout(() => {
                        setStatusAlert(false);
                        setMessage("");
                        setSeverity("");
                        document.getElementById("hideButton").style.display =
                            "block";
                    }, 3500);
                });
        }
        rel();
    };

    return (
        <section className="dash__details">
            <div className="details__top">
                {repair ? (
                    <table>
                        <thead>
                            <tr>
                                <th>Submited on:</th>
                                <th>Last Updated on:</th>
                                <th>Assigned to:</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>
                                    {new Date(
                                        repair.submissionDate
                                    ).toLocaleDateString()}
                                </th>
                                <th>
                                    {new Date(
                                        repair.lastUpdate
                                    ).toLocaleDateString()}
                                </th>
                                <th>{repair.assignedTo}</th>
                            </tr>
                        </tbody>
                    </table>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th>Submited on:</th>
                                <th>Last Updated on:</th>
                                <th>Assigned to:</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>N/A</th>
                                <th>N/A</th>
                                <th>N/A</th>
                            </tr>
                        </tbody>
                    </table>
                )}
            </div>
            <div className="details__top">
                {repair ? (
                    <table>
                        <thead>
                            <tr>
                                <th>Issue</th>
                                <th>Status</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>{repair.type}</th>
                                <th>{repair.status}</th>
                                <th>{repair.issue}</th>
                            </tr>
                        </tbody>
                    </table>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th>Issue</th>
                                <th>Status</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>N/A</th>
                                <th>N/A</th>
                                <th>N/A</th>
                            </tr>
                        </tbody>
                    </table>
                )}
            </div>
            <div className="details__bottom">
                <div className="bottom__left">
                    <div className="box__left">
                        <h2>
                            Status{" "}
                            <Tooltip title="Change Status">
                                <IconButton onClick={() => handleStatusOpen()}>
                                    <MoreHorizIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                        </h2>
                        {repair ? (
                            <>
                                {/* <p>
                  Type: <span>{repair.type}</span>
                </p>
                <p>
                  Make: <span>{repair.make}</span>
                </p> */}
                                <p>
                                    Ticket: <span>{repair.ticketNumber}</span>
                                </p>
                                {/* <p>
                  Issue: <span>{repair.issue}</span>
                </p> */}

                                <Modal
                                    open={statusOpen}
                                    aria-labelledby="Change Status Modal"
                                    aria-describedby="Change Status Modal"
                                    className="modal"
                                >
                                    <ChangeStatus
                                        setStatus={setStatus}
                                        statusAlert={statusAlert}
                                        changeStatus={changeStatus}
                                        severity={severity}
                                        message={message}
                                        repair={repair}
                                        handleStatusClose={handleStatusClose}
                                        showLoader={showLoader}
                                    />
                                </Modal>
                            </>
                        ) : (
                            <>
                                <p>
                                    Type: <span>N/A</span>
                                </p>
                                <p>
                                    Make: <span>N/A</span>
                                </p>
                            </>
                        )}
                    </div>
                    <div className="box__left">
                        <h2>Logs</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Update Date</th>
                                    <th>Updated</th>
                                </tr>
                            </thead>
                            {audit ? (
                                <tbody>
                                    {audit.map(function (d, i) {
                                        return (
                                            <tr>
                                                <th>{d.newStatus}</th>
                                                <th>
                                                    {new Date(
                                                        d.updateDate
                                                    ).toLocaleDateString()}
                                                </th>
                                                <th>{d.updatedBy}</th>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            ) : (
                                <></>
                            )}
                        </table>
                    </div>
                    <div className="box__left">
                        <h2>Assign to:</h2>
                        <select
                            onChange={(e) => setAsignTo(e.target.value)}
                            value={asignTo}
                        >
                            <option>--Asign this job to--</option>
                            {userList.map(function (d, i) {
                                return (
                                    <option value={d.username}>
                                        {d.username}
                                    </option>
                                );
                            })}
                        </select>
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{ margin: "auto" }}
                            onClick={() => assignJob()}
                            id="asign_btn"
                        >
                            Assign
                        </Button>
                        {asignAlert ? (
                            <Alert severity={severity}>{message}</Alert>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                <div className="box__right">
                    <h2>Notes</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Added By</th>
                                <th>Note</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {notes ? (
                                notes.map(function (d, i) {
                                    return (
                                        <tr>
                                            <th>{d.addedBy}</th>
                                            <th>{d.note}</th>
                                            <th>
                                                {new Date(
                                                    d.addedDate
                                                ).toLocaleDateString()}
                                            </th>
                                        </tr>
                                    );
                                })
                            ) : (
                                <></>
                            )}
                        </tbody>
                    </table>
                    <div className="add__note">
                        <IconButton onClick={() => handleOpen()}>
                            <AddCircleIcon color="primary" fontSize="large" />
                        </IconButton>
                    </div>
                    <Modal
                        open={open}
                        aria-labelledby="Notes Modal"
                        aria-describedby="Notes Modal"
                    >
                        <AddNote
                            setNote={setNote}
                            addNote={addNote}
                            handleClose={handleClose}
                        />
                    </Modal>
                </div>
            </div>
        </section>
    );
}

import { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import axios from "axios";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import reCAPTCHA from "react-google-recaptcha"

export default function Form(props) {
  const [site, setSite] = useState("");
  const [name, setName] = useState("");
  const [issue, setIssue] = useState("");
  const [date, setDate] = useState("");
  const [type, setType] = useState("");
  const [check, setCheck] = useState(false);
  const [logDate, setLogDate] = useState("");
  const [showSendButton, setShowSendButton] = useState(true);
  const [sites, setSites] = useState([]);
  const [completionAlert, setCompletionAlert] = useState(false);
  const [sentAlert, setSentAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [make, setMake] = useState("");
  const [priority, setPriority] = useState("");
  const [issueAlert, setIssueAlert] = useState(false);
  const [assetTag, setAssetTag] = useState("")

  useEffect(() => {
    axios
      .get("https://nitrous.rt-itservices.co.uk/sites/data")
      .then((res) => {
        setSites(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function submit() {
    if (issue.length < 25) {
      setIssueAlert(true)
      setTimeout(() => {
        setIssueAlert(false)
      }, 2000)
    } else if (site === "" || name === "" || issue === "" || type === "--Select One--" || make === "" || priority === "--Select One--" || assetTag === "") {
      setCompletionAlert(true)
      setTimeout(() => {
        setCompletionAlert(false)
      }, 2500)
    }
    else {
      setShowSendButton(false);
      let data = {
        site: site,
        name: name,
        issue: issue,
        date: date,
        type: type,
        make: make,
        priority: priority,
        assetTag: assetTag,
      };
      axios
        .post("https://nitrous.rt-itservices.co.uk/itSupport", data)
        .then((res) => {
          setSentAlert(true);
          setTimeout(() => {
            setSentAlert(false);
            if (props) {
              if (props.manual == true) {
                props.rel();
              }
            }
          }, 2000);
          setShowSendButton(true);
          reset()
        })
        .catch((err) => {
          console.log(err);
          setErrorAlert(true);
          setTimeout(() => {
            setErrorAlert(false);
          }, 2000);
          setShowSendButton(true);
        });
    }
  }

  function reset() {
    setName("")
    setIssue("")
    setDate("")
    setMake("")
    var selects = document.querySelectorAll("select")
    for (let i = 0; i < selects.length; i++) {
      selects[i].selectedIndex = 0
    }
  }
  return (
    <section className="form">
      {completionAlert ? (
        <Alert
          variant="filled"
          severity="warning"
          sx={{
            width: "90vw",
            margin: "auto",
            position: "absolute",
            top: "90vh",
          }}
        >
          All fields must be filled, please try again.
        </Alert>
      ) : (
        <></>
      )}
      {sentAlert ? (
        <Alert
          variant="filled"
          severity="success"
          sx={{
            width: "90vw",
            margin: "auto",
          }}
        >
          Form sent to IT
        </Alert>
      ) : (
        <></>
      )}
      {errorAlert ? (
        <Alert
          variant="filled"
          severity="error"
          sx={{
            width: "90vw",
            margin: "auto",
          }}
        >
          Could not send form, please try again
        </Alert>
      ) : (
        <></>
      )}
      {issueAlert ? (
        <Alert
          variant="filled"
          severity="warning"
          sx={{
            width: "90vw",
            margin: "auto",
            position: "absolute",
            top: "60vh",
            zIndex: "10"
          }}
        >
          Please expand your description of the issue
        </Alert>
      ) : (
        <></>
      )}
      <h2>Please give as much information as possible about the fault</h2>
      <select onChange={(e) => setSite(e.target.value)} required>
        <option>Select your site</option>
        {sites.map(function (d, i) {
          return <option>{d.name}</option>;
        })}
        <option>Crow Rd</option>
      </select>
      <label>
        Date<span>*</span>
      </label>
      <input type="date" onChange={(e) => setDate(e.target.value)} />
      <label>
        Your name<span>*</span>
      </label>
      <input type="text" onChange={(e) => setName(e.target.value)} required />
      <label>
        Please Select between Hardware or Software <span>*</span>
      </label>
      <select onChange={(e) => setType(e.target.value)}>
        <option>--Select One--</option>
        <option>Software</option>
        <option>Hardware</option>
      </select>
      {type === "Software" ? (
        <label>
          Software Name <span>*</span>
        </label>
      ) : type === "Hardware" ? (
        <label>
          Hardware Type <span>*</span>
        </label>
      ) : (
        <label>
          Machine Type<span>*</span>
        </label>
      )}
      <input
        type="text"
        onChange={(e) => setMake(e.target.value)}
        required
      />
      <label>Asset Tag/Serial Number<span>*</span></label>
      <input type="text" onChange={(e) => setAssetTag(e.target.value)} />
      <label>
        Description<span>*</span>
      </label>
      <textarea
        type="text"
        onChange={(e) => setIssue(e.target.value)}
        cols="40"
        rows="5"
        required
      />
      <br />
      <label>
        Priority <span>*</span>
      </label>
      <select onChange={(e) => setPriority(e.target.value)}>
        <option>--Select One--</option>
        <option>Critical (Site Down)</option>
        <option>High</option>
        <option>Medium</option>
        <option>Low</option>
      </select>
      {/* <div
        style={{
          border: "1px solid #2c53a0",
          borderRadius: "10px",
          padding: "10px",
          width: "375px",
          textAlign: "center",
        }}
      >
        <label>Click Here If You Have Logged This Issue</label>
        <input
          type="checkbox"
          value={check}
          onChange={(e) => {
            setCheck(!check);
            console.log(check);
          }}
        />
        {check ? (
          <div>
            <hr />
            <p>Date &amp; Time Logged</p>
            <input
              type="datetime-local"
              required
              value={logDate}
              onChange={(e) => setLogDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </div>
        ) : (
          <></>
        )}
      </div> */}
      <reCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} />
      {showSendButton ? (
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          sx={{ alignSelf: "center", marginTop: "2%" }}
          onClick={() => submit()}
        >
          Send
        </Button>
      ) : (
        <p
          style={{
            textAlign: "center",
            color: "#2c53a0",
            textTransform: "uppercase",
          }}
        >
          We are sending your form
        </p>
      )}
    </section>
  );
}

import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";

export default function UpdateStatus({
    openStatus,
    style,
    d,
    setStatus,
    setStaff,
    statusUpdate,
}) {
    return (
        <Fade in={openStatus}>
            <Box sx={style}>
                <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                >
                    Update Status
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                    <label>Current Status</label>
                    <br />
                    <b style={{ textDecoration: "underline" }}>{d.status}</b>
                    <br />
                    <label>New Status</label>
                    <br />
                    <input
                        name="radStatus"
                        type="radio"
                        value="Investigation Started"
                        onChange={(e) => setStatus(e.target.value)}
                    />
                    <label>Investigation Started</label>
                    <br />
                    <input
                        name="radStatus"
                        type="radio"
                        value="Repair Arranged"
                        onChange={(e) => setStatus(e.target.value)}
                    />
                    <label>IT Arrange to go to Site</label>
                    <br />
                    <input
                        name="radStatus"
                        type="radio"
                        value="Waiting On Parts"
                        onChange={(e) => setStatus(e.target.value)}
                    />
                    <label>Waiting On Parts</label>
                    <br />
                    <input
                        name="radStatus"
                        type="radio"
                        value="Repair Complete"
                        onChange={(e) => setStatus(e.target.value)}
                    />
                    <label>Repair Complete</label>
                    <br />
                    <label>Staff Name:</label>
                    <br />
                    <input
                        type="text"
                        onChange={(e) => setStaff(e.target.value)}
                    />
                    <br />
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => statusUpdate(d.issueID)}
                        className="button"
                        id="hideButton"
                    >
                        Send
                    </Button>
                </Typography>
            </Box>
        </Fade>
    );
}

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { Alert } from "@mui/material";
import Loader from "../../animations/Loader";

const statusStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "25vw",
    minHeight: "35vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "1rem",
    overflowY: "scroll",
};

export default function ChangeStatus({
    setStatus,
    statusAlert,
    changeStatus,
    severity,
    message,
    repair,
    handleStatusClose,
    showLoader,
}) {
    return (
        <Box sx={statusStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Change Status
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div className="status__modal">
                    <label>Current Status: </label>
                    <b
                        style={{
                            textDecoration: "underline",
                            color: "#D90D1E",
                        }}
                    >
                        {repair.status}
                    </b>
                    <br />
                    <label>New Status:</label>
                    <div className="status__row">
                        <input
                            name="radStatus"
                            type="radio"
                            value="Investigation Started"
                            onChange={(e) => setStatus(e.target.value)}
                        />
                        <label>Investigation Started</label>
                    </div>
                    <div className="status__row">
                        <input
                            name="radStatus"
                            type="radio"
                            value="Repair Arranged"
                            onChange={(e) => setStatus(e.target.value)}
                        />
                        <label>IT Arrange to go to Site</label>
                    </div>
                    <div className="status__row">
                        <input
                            name="radStatus"
                            type="radio"
                            value="Waiting On Parts"
                            onChange={(e) => setStatus(e.target.value)}
                        />
                        <label>Waiting On Parts</label>
                    </div>
                    <div className="status__row">
                        <input
                            name="radStatus"
                            type="radio"
                            value="Repair Complete"
                            onChange={(e) => setStatus(e.target.value)}
                        />
                        <label>Repair Complete</label>
                    </div>
                    <div className="status__row">
                        <input
                            name="radStatus"
                            type="radio"
                            value="Third Party Support"
                            onChange={(e) => setStatus(e.target.value)}
                        />
                        <label>Third Party Support</label>
                    </div>
                    {statusAlert ? (
                        <Alert severity={severity}>{message}</Alert>
                    ) : (
                        <></>
                    )}
                    {!showLoader ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "15px",
                            }}
                        >
                            <Button
                                variant="contained"
                                color="warning"
                                onClick={handleStatusClose}
                            >
                                close
                            </Button>
                            <Button
                                variant="contained"
                                id="hideButton"
                                onClick={() => changeStatus()}
                            >
                                Change Status
                            </Button>
                        </div>
                    ) : (
                        <Loader />
                    )}
                </div>
            </Typography>
        </Box>
    );
}

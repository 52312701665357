import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    minHeight: "30vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "1rem",
    overflowY: "scroll",
};

export default function AddNote({ setNote, addNote, handleClose }) {
    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Add a new note
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div className="note__modal">
                    <textarea
                        cols={120}
                        rows={6}
                        placeholder="Add new note..."
                        style={{ margin: "auto" }}
                        onChange={(e) => setNote(e.target.value)}
                    />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "15px",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={handleClose}
                        >
                            close
                        </Button>
                        <Button variant="contained" onClick={() => addNote()}>
                            Add Note
                        </Button>
                    </div>
                </div>
            </Typography>
        </Box>
    );
}

import { useState, useEffect } from "react";
import "./admin.scss";
import TuneIcon from "@mui/icons-material/Tune";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FlakyIcon from "@mui/icons-material/Flaky";
import axios from "axios";
import Modal from "@mui/material/Modal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CompletedJobs from "./modals/CompletedJobs";

export default function History(props) {
    const [data, setData] = useState(props.data);
    const [user, setUser] = useState("");
    const [reload, setReload] = useState(false);
    const [open, setOpen] = useState();
    const [investigationStarted, setInvestigationStarted] = useState();
    const [waitingParts, setWaitingParts] = useState();
    const [openComleted, setOpenCompleted] = useState(false);
    const handleOpen = () => setOpenCompleted(true);
    const handleClose = () => {
        setOpenCompleted(false);
    };
    const [completed, setCompleted] = useState([]);
    const [status, setStatus] = useState("");
    const [userFilter, setUserFilter] = useState(true);

    useEffect(() => {
        setData(props.data);
        let user = localStorage.getItem("user");
        setUser(user);
        setOpen(props.data.length);

        let investigation = props.data.filter(
            (i) => i.status === "Investigation Started"
        );
        setInvestigationStarted(investigation.length);

        let waiting = props.data.filter((i) => i.status === "Waiting On Parts");
        setWaitingParts(waiting.length);
    }, [props, reload]);

    const rel = () => {
        setReload(reload);
    };

    const filterByAssigned = () => {
        setUserFilter(!userFilter);
        if (userFilter) {
            let assigned = props.data.filter((i) => i.assignedTo === user);
            setData(assigned);
        } else {
            setData(props.data);
        }
        rel();
    };

    const handleCompleted = async () => {
        axios
            .get("https://nitrous.rt-itservices.co.uk/itSupport/compleded")
            .then((res) => {
                // console.log(res.data);
                setCompleted(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        handleOpen();
    };

    const handleCompletedStatus = async (id) => {
        if (status === "" || status === "--Change Status--") {
            alert("please select status");
        } else {
            let data = {
                id: id,
                staffName: user,
                status: status,
            };
            await axios
                .post(
                    "https://nitrous.rt-itservices.co.uk/itSupport/update",
                    data
                )
                .then((res) => {
                    handleClose();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <aside className="dash__aside">
            <div className="aside__icon">
                <Tooltip title="filter by assigned to me only">
                    <IconButton onClick={() => filterByAssigned()}>
                        <TuneIcon color="primary" />
                    </IconButton>
                </Tooltip>
            </div>
            <div className="aside__row">
                <div className="box">
                    <p>Open</p>
                    <p>{open}</p>
                </div>
                <div className="box">
                    <p>Investigation Started</p>
                    <p>{investigationStarted}</p>
                </div>
                <div className="box">
                    <p>Waiting on Parts</p>
                    <p>{waitingParts}</p>
                </div>
            </div>
            <div className="manualForm">
                <Tooltip title="See completed jobs">
                    <IconButton onClick={() => handleCompleted()}>
                        <FlakyIcon color="primary" fontSize="medium" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Manual Submission">
                    <IconButton onClick={() => props.manualSubmission()}>
                        <AddCircleIcon color="primary" />
                    </IconButton>
                </Tooltip>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Site</th>
                        <th>Hardware/Software</th>
                        <th>Status</th>
                        <th>Opened on</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(function (d, i) {
                        return (
                            <tr onClick={() => props.handleRepair(d)} id={i}>
                                <th>{d.site}</th>
                                <th>{d.make}</th>
                                <th>{d.status}</th>
                                <th>
                                    {new Date(
                                        d.submissionDate
                                    ).toLocaleDateString()}
                                </th>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="complete__icon">
                <Modal
                    open={openComleted}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="modal"
                >
                    <CompletedJobs
                        completed={completed}
                        setStatus={setStatus}
                        handleCompletedStatus={handleCompletedStatus}
                    />
                </Modal>
            </div>
        </aside>
    );
}

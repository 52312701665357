import { useState, useEffect } from "react";
import "./nav.scss";

export default function Nav(props) {
  const [user, setUser] = useState(props.user);

  return (
    <nav className="dash__nav">
      <h1 id="dash">IT Help Desk</h1>
      <p>
        Logged in as: <span>{user}</span>
      </p>
    </nav>
  );
}

import { useState, useEffect } from "react";
import axios from "axios";
import "./admin.scss";
import Logo from "../images/Logo.png";
import Alert from "@mui/material/Alert";


export default function Login(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);



  const handleAccess = async (e) => {
    e.preventDefault();

    const regex = /^[A-Za-z]+\.[A-Za-z]+$/;
    if (username.match(regex)) {

      try {
        const user = { username, password };
        const loginRes = await axios.post(
          "https://nitrous.rt-itservices.co.uk/itSupport/login",
          user
        );

        //set user data to
        console.log(loginRes.data);
        localStorage.setItem("user", loginRes.data.user.username);
        props.rel()

      } catch (err) {
        err.response.data.msg && setMessage(err.response.data.msg);
        console.log(err.response.data.msg);
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 2500);
      }
    } else {
      setMessage("Username or password is wrong, please try again");
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 2500);
    }


  };

  return (
    <section className="login">
        {alert ? (
          <Alert severity="error" sx={{position: "absolute", width: "80vw", left: "10vw", top: "25vh"}}>{message}</Alert>  
        ):(
            <></>
        )}
      <img src={Logo} alt="Nitro Logo" />
      <form onSubmit={handleAccess}>
        <input
          type="text"
          placeholder="Username"
          name="username"
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          name="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <input type="submit" value="Login" />
      </form>
    </section>
  );
}

import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import axios from "axios";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import Button from "@mui/material/Button";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import DescriptionIcon from "@mui/icons-material/Description";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import { css } from "@emotion/react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Notes from "./modals/Notes";
import Description from "./modals/Description";
import UpdateStatus from "./modals/UpdateStatus";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function IndvStatus(props) {
    const [open, setOpen] = useState(false);
    const [openDescription, setOpenDescription] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleOpenDescription = () => setOpenDescription(true);
    const handleCloseDescription = () => setOpenDescription(false);
    const [openStatus, setOpenStatus] = useState(false);
    const openModal = () => setOpenStatus(true);
    const closeModal = () => setOpenStatus(false);
    const [staff, setStaff] = useState("");
    const [status, setStatus] = useState("");
    const [reload, setReload] = useState(false);
    const [colour, setColour] = useState("");
    const [note, setNote] = useState("");
    const [name, setName] = useState("");
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#be1a1a");
    const [notes, setNotes] = useState([]);
    const [openCritical, setOpenCritical] = useState(false);
    const criticalOpen = () => setOpenCritical(true);
    const criticalClose = () => setOpenCritical(false);
    const [priority, setPriority] = useState("");
    const [otherCritical, setOtherCritical] = useState(false);
    const criticalOtherOpen = () => setOtherCritical(true);
    const criticalOtherClose = () => setOtherCritical(false);

    const override = css`
        display: block;
        margin-left: 40%;
        margin-top: 5%;
        border-color: red;
    `;

    useEffect(() => {
        axios
            .get(
                `https://nitrous.rt-itservices.co.uk/itSupport/search/?site=${props.d.issueID}`
            )
            .then((res) => {
                setNotes(res.data[0].notes);
                if (res.data[0].notes.length > 0) {
                    setColour("#2c53a0");
                } else {
                    setColour("");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [props.d.issueID, reload]);

    async function statusUpdate(id) {
        let data = {
            id: id,
            staffName: staff,
            status: status,
        };
        document.getElementById("hideButton").style.display = "none";
        await axios
            .post("https://nitrous.rt-itservices.co.uk/itSupport/update", data)
            .then((res) => {
                props.rel();
                props.result();
                setReload(!reload);
                alert("Status Updated");
                closeModal();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async function priorityUpdate(id) {
        let data = {
            id: id,
            staffName: staff,
            priority: priority,
        };
        document.getElementById("hideButton").style.display = "none";
        await axios
            .post(
                "https://nitrous.rt-itservices.co.uk/itSupport/priority-update",
                data
            )
            .then((res) => {
                props.rel();
                props.result();
                setReload(!reload);
                alert("Priority Updated");
                criticalClose();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const addNote = async () => {
        setLoading(true);
        var noteData = { id: props.d.issueID, note: note, staffName: name };
        await axios
            .post(
                "https://nitrous.rt-itservices.co.uk/itSupport/addNote",
                noteData
            )
            .then((res) => {
                alert("A Note Has Been Added To This Issue!");
                setReload(!reload);
                setName("");
                setNote("");
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <tr>
            <td>{props.d.site}</td>
            <td>{props.d.name}</td>
            <td>{props.d.date}</td>
            <td>
                {props.d.type}
                <br />
                {props.d.make}
            </td>
            {props.d.priority === "Critical (Site Down)" ? (
                <>
                    <td
                        className="critical"
                        onClick={criticalOpen}
                        style={{ cursor: "pointer" }}
                    >
                        <WarningAmberIcon sx={{ color: "#fff" }} />
                        {props.d.priority}
                    </td>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openCritical}
                        onClose={criticalClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openCritical}>
                            <Box sx={style}>
                                <Typography
                                    id="transition-modal-title"
                                    variant="h6"
                                    component="h2"
                                    textAlign="center"
                                >
                                    Update Priority
                                </Typography>
                                <Typography
                                    id="transition-modal-description"
                                    sx={{ mt: 2 }}
                                >
                                    <label>Current Priority</label>
                                    <br />
                                    <b style={{ textDecoration: "underline" }}>
                                        {props.d.priority}
                                    </b>
                                    <hr />
                                    <input
                                        name="radStatus"
                                        type="radio"
                                        value="Critical (Site Down)"
                                        onChange={(e) =>
                                            setPriority(e.target.value)
                                        }
                                    />

                                    <label>Critical (Site Down)</label>
                                    <br />
                                    <input
                                        name="radStatus"
                                        type="radio"
                                        value="High"
                                        onChange={(e) =>
                                            setPriority(e.target.value)
                                        }
                                    />
                                    <label>High</label>
                                    <br />
                                    <input
                                        name="radStatus"
                                        type="radio"
                                        value="Medium"
                                        onChange={(e) =>
                                            setPriority(e.target.value)
                                        }
                                    />
                                    <label>Medium</label>
                                    <br />
                                    <input
                                        name="radStatus"
                                        type="radio"
                                        value="Low"
                                        onChange={(e) =>
                                            setPriority(e.target.value)
                                        }
                                    />
                                    <label>Low</label>
                                    <br />
                                    <label>Staff Name:</label>
                                    <br />
                                    <input
                                        type="text"
                                        onChange={(e) =>
                                            setStaff(e.target.value)
                                        }
                                    />
                                    <br />
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                            priorityUpdate(props.d.issueID)
                                        }
                                        className="button"
                                        id="hideButton"
                                        sx={{ marginTop: "1%" }}
                                    >
                                        Send
                                    </Button>
                                </Typography>
                            </Box>
                        </Fade>
                    </Modal>
                </>
            ) : (
                <>
                    <td
                        style={{ cursor: "pointer" }}
                        onClick={criticalOtherOpen}
                    >
                        {props.d.priority}
                    </td>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={otherCritical}
                        onClose={criticalOtherClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={otherCritical}>
                            <Box sx={style}>
                                <Typography
                                    id="transition-modal-title"
                                    variant="h6"
                                    component="h2"
                                    textAlign="center"
                                >
                                    Update Priority
                                </Typography>
                                <Typography
                                    id="transition-modal-description"
                                    sx={{ mt: 2 }}
                                >
                                    <label>Current Priority</label>
                                    <br />
                                    <b style={{ textDecoration: "underline" }}>
                                        {props.d.priority}
                                    </b>
                                    <hr />
                                    <input
                                        name="radStatus"
                                        type="radio"
                                        value="Critical (Site Down)"
                                        onChange={(e) =>
                                            setPriority(e.target.value)
                                        }
                                    />

                                    <label>Critical (Site Down)</label>
                                    <br />
                                    <input
                                        name="radStatus"
                                        type="radio"
                                        value="High"
                                        onChange={(e) =>
                                            setPriority(e.target.value)
                                        }
                                    />
                                    <label>High</label>
                                    <br />
                                    <input
                                        name="radStatus"
                                        type="radio"
                                        value="Medium"
                                        onChange={(e) =>
                                            setPriority(e.target.value)
                                        }
                                    />
                                    <label>Medium</label>
                                    <br />
                                    <input
                                        name="radStatus"
                                        type="radio"
                                        value="Low"
                                        onChange={(e) =>
                                            setPriority(e.target.value)
                                        }
                                    />
                                    <label>Low</label>
                                    <br />
                                    <label>Staff Name:</label>
                                    <br />
                                    <input
                                        type="text"
                                        onChange={(e) =>
                                            setStaff(e.target.value)
                                        }
                                    />
                                    <br />
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                            priorityUpdate(props.d.issueID)
                                        }
                                        className="button"
                                        id="hideButton"
                                        sx={{ marginTop: "1%" }}
                                    >
                                        Send
                                    </Button>
                                </Typography>
                            </Box>
                        </Fade>
                    </Modal>
                </>
            )}
            <td>
                {props.d.status}
                <Tooltip title="Update Status">
                    <IconButton onClick={openModal}>
                        <PlaylistAddCheckIcon />
                    </IconButton>
                </Tooltip>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openStatus}
                    onClose={closeModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <UpdateStatus
                        openStatus={openStatus}
                        style={style}
                        d={props.d}
                        setStatus={setStatus}
                        setStaff={setStaff}
                        statusUpdate={statusUpdate}
                    />
                </Modal>
            </td>
            <td>
                <Tooltip title="See Description">
                    <IconButton onClick={handleOpenDescription}>
                        <DescriptionIcon />
                    </IconButton>
                </Tooltip>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openDescription}
                    onClose={handleCloseDescription}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Description
                        openDescription={openDescription}
                        style={style}
                        d={props.d}
                    />
                </Modal>
            </td>
            <td style={{ backgroundColor: colour }}>
                <Tooltip title="See Notes">
                    <IconButton onClick={handleOpen}>
                        {notes.length === 0 ? (
                            <div>
                                <SpeakerNotesOffIcon />
                            </div>
                        ) : (
                            <div id="notesIcon">
                                <SpeakerNotesIcon />
                            </div>
                        )}
                    </IconButton>
                </Tooltip>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Notes
                        style={style}
                        open={open}
                        notes={notes}
                        setNote={setNote}
                        setName={setName}
                        addNote={addNote}
                    />
                </Modal>
            </td>
        </tr>
    );
}

import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function Notes({
    style,
    open,
    notes,
    setNote,
    setName,
    addNote,
}) {
    return (
        <Fade in={open}>
            <Box sx={style}>
                <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                >
                    <u>Notes</u>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                    {notes.length > 0 ? (
                        <table>
                            <tr
                                style={{
                                    backgroundColor: "#2c53a0",
                                    color: "white",
                                }}
                            >
                                <th>Note</th>
                                <th>Added By</th>
                                <th>Date</th>
                            </tr>
                            {notes.map(function (d, i) {
                                return (
                                    <tr style={{ border: "1px solid #2c53a0" }}>
                                        <td
                                            style={{
                                                border: "1px solid #2c53a0",
                                            }}
                                        >
                                            {d.note}
                                        </td>
                                        <td
                                            style={{
                                                border: "1px solid #2c53a0",
                                            }}
                                        >
                                            {d.addedBy}
                                        </td>
                                        <td
                                            style={{
                                                border: "1px solid #2c53a0",
                                            }}
                                        >
                                            {new Date(
                                                d.addedDate
                                            ).toUTCString()}
                                        </td>
                                    </tr>
                                );
                            })}
                        </table>
                    ) : (
                        <p>No Notes Have Been Added</p>
                    )}
                    <hr />
                    <div>
                        <h4>
                            <u>Add Note</u>
                        </h4>
                        <label>Note: </label>
                        <input
                            style={{
                                width: "100%",
                                padding: "5px",
                                margin: "10px",
                            }}
                            type="text"
                            placeholder="Enter Note..."
                            onChange={(e) => setNote(e.target.value)}
                            required
                        />
                        <br />
                        <label>Your Name: </label>
                        <input
                            style={{
                                width: "100%",
                                padding: "5px",
                                margin: "10px",
                            }}
                            type="text"
                            placeholder="Enter Your Name..."
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                        <button
                            style={{
                                width: "100%",
                                padding: "5px",
                                margin: "10px",
                                backgroundColor: "#2c53a0",
                                color: "white",
                            }}
                            onClick={() => addNote()}
                        >
                            Add Note
                        </button>
                    </div>
                </Typography>
            </Box>
        </Fade>
    );
}
